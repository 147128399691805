import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsComponent } from './components/contacts/contacts.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { WorkDetailComponent } from './components/work-detail/work-detail.component';
import { WeddingsComponent } from './components/weddings/weddings.component';


const routes: Routes = [
  { path: 'main', component: HomepageComponent },
  { path: 'work', component: HomepageComponent },
  { path: 'travel', component: HomepageComponent },
  { path: 'brands', component: HomepageComponent },
  { path: 'weddings', component: WeddingsComponent },
  { path: 'documentaries', component: HomepageComponent },
  { path: 'contact', component: ContactsComponent },
  { path:'',redirectTo:'main', pathMatch: 'full' },
  { path: 'main/:title', component: WorkDetailComponent},
  { path: 'work/:title', component: WorkDetailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
