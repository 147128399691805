import { Component, OnInit } from '@angular/core';
import { WorksService } from 'src/app/services/works.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  works = [];

  clients = [
    'oppo',
    'bd',
    'canon',
    'merrel',
    'wandrd',
    'airback',
    'naturea',
    'quad'
  ]

  constructor(private worksService : WorksService) { }

  ngOnInit(): void {

    let array = window.location.href.split("/");
    let type = array[array.length-1];

    this.works = this.worksService.getAll(type);

  }

}
