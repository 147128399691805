


  <div class="container text-center">
    <h1 class="work-title">Weddings</h1>

    <div class="ratio ratio-16x9 my-5">
      <iframe
        src="https://www.youtube.com/embed/jyrAYxfm2rU"
        title="YouTube video"
        allowfullscreen></iframe>
    </div>
    <div class="ratio ratio-16x9">
      <iframe
        src="https://www.youtube.com/embed/I9GCUOjb6JM"
        title="YouTube video"
        allowfullscreen></iframe>
    </div>
  </div>
